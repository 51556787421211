import React, {
  useState,
  useEffect,
  useCallback,
  createRef,
} from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useSwipeable } from 'react-swipeable';
import Box from '@nubank/nuds-web/components/Box/Box';
import Button from '@nubank/nuds-web/components/Button/Button';
import { useViewportWidth } from '@nubank/nuds-web/styles/breakpoints';

import carouselLogic from './carousel';
import { StyledCarousel } from './style';

const MARGIN = 16;

const InfiniteCarouselItem = ({ cardSize, ...props }) => (
  <Box flex={`0 0 ${cardSize}px`} marginHorizontal={MARGIN} {...props} />
);

InfiniteCarouselItem.propTypes = {
  cardSize: PropTypes.number.isRequired,
};

function InfiniteCarousel({
  // TODO: find a way to pass itemMargin directly to Carousel.Item
  children, cardSize, initialOffset, itemMargin, animationTimingBase, onInteraction,
}) {
  const { formatMessage } = useIntl();
  const items = React.Children.toArray(children);

  const [step, setStep] = useState({ from: 0, to: 0, direction: 'next' });
  const [carouselHeight, setCarouselHeight] = useState('auto');
  const [carousel, setCarousel] = useState(items);
  const clientWidth = useViewportWidth();

  const carouselRef = createRef();

  const totalCardSize = 2 * itemMargin + cardSize;
  // partial visible card + left margin of the 1st visible item
  const calculedOffset = totalCardSize + itemMargin - initialOffset;
  const numberOfVisibleElements = Math.max(1, Math.floor(clientWidth / totalCardSize));

  useEffect(() => {
    setCarouselHeight(carouselRef.current.clientHeight);
    setCarousel(carouselLogic.createSlides(items, step.from, numberOfVisibleElements, cardSize));
    carouselRef.current.style.transition = null;
    carouselRef.current.style.transform = `translate3d(-${(cardSize + 2 * itemMargin) * numberOfVisibleElements + calculedOffset}px, 0, 0)`;
  }, [clientWidth]);

  useEffect(() => {
    const ref = carouselRef.current;
    const differenceInSteps = step.to - step.from;

    const additionalCarouselElements = differenceInSteps > 0
      ? differenceInSteps - numberOfVisibleElements
      : differenceInSteps + numberOfVisibleElements;
    const allCarouselElements = carouselLogic.createSlides(
      items, step.from, numberOfVisibleElements, cardSize, additionalCarouselElements,
    );
    setCarousel(allCarouselElements);

    // do not animate on first render
    if (step.from === step.to) {
      return;
    }

    // hidden panel + visible panel + additional steps
    const nextStepPosition = totalCardSize
      * ((2 * numberOfVisibleElements) + Math.abs(additionalCarouselElements));

    const translate = step.direction === 'prev'
      ? calculedOffset
      : nextStepPosition + calculedOffset;

    // when there is more than one click on prev, we need to recalc the transition position
    if (differenceInSteps < 0) {
      const computedStyle = getComputedStyle(ref);
      ref.style.transition = null;
      const moved = parseFloat(computedStyle.transform.split(',')[4]) - totalCardSize * differenceInSteps;
      ref.style.transform = `translate3d(-${moved}px, 0, 0)`;
    }
    setTimeout(() => {
      const totalAnimationTiming = animationTimingBase * numberOfVisibleElements;
      if (ref.style.transition !== `all ${totalAnimationTiming}ms ease-out`) {
        ref.style.transition = `all ${totalAnimationTiming}ms ease-out`;
      }
      ref.style.transform = `translate3d(-${translate}px, 0, 0)`;
    }, 0);
  }, [step.to]);

  const onAnimationEnd = () => {
    setCarousel(carouselLogic.createSlides(items, step.to, numberOfVisibleElements, cardSize, 0));
    carouselRef.current.style.transition = null;
    carouselRef.current.style.transform = `translate3d(-${(cardSize + 2 * itemMargin) * numberOfVisibleElements + calculedOffset}px, 0, 0)`;
    setStep({ from: step.to, to: step.to, direction: 'prev' });
  };

  const handleTransitionEnd = () => useCallback(() => {
    onAnimationEnd();
  }, [step, carouselRef]);

  const handlePrevChange = () => {
    setStep({ from: step.from, to: step.to - numberOfVisibleElements, direction: 'prev' });
    onInteraction('previous');
  };

  const handleNextChange = () => {
    setStep({ from: step.from, to: step.to + numberOfVisibleElements, direction: 'next' });
    onInteraction('next');
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleNextChange,
    onSwipedRight: handlePrevChange,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <>
      <Box overflow="hidden">
        <div {...handlers}>
          <StyledCarousel
            height={carouselHeight}
            onTransitionEnd={handleTransitionEnd()}
            ref={carouselRef}
            id="infinite-carousel"
            ariaLive="polite"
          >
            {carousel}
          </StyledCarousel>
        </div>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        width="106px"
        marginHorizontal="auto"
        marginTop={{ xs: '5x', lg: '6x' }}
        ariaControls="infinite-carousel"
      >
        <Button
          onClick={handlePrevChange}
          variant="basic"
          styleVariant="black"
          iconProps={{ name: 'arrow-left' }}
          aria-label={formatMessage({ id: 'CAROUSEL.PREVIOUS_BUTTON.LABEL' })}
        />
        <Button
          onClick={handleNextChange}
          variant="basic"
          styleVariant="black"
          iconProps={{ name: 'arrow-right' }}
          aria-label={formatMessage({ id: 'CAROUSEL.NEXT_BUTTON.LABEL' })}
        />
      </Box>
    </>
  );
}

InfiniteCarousel.defaultProps = {
  cardSize: 312,
  animationTimingBase: 200,
  initialOffset: 140,
  itemMargin: MARGIN,
  onInteraction: () => {},
};

InfiniteCarousel.propTypes = {
  animationTimingBase: PropTypes.number,
  cardSize: PropTypes.number,
  children: PropTypes.node.isRequired,
  initialOffset: PropTypes.number,
  itemMargin: PropTypes.number,
  onInteraction: PropTypes.func,
};

InfiniteCarousel.Item = InfiniteCarouselItem;

export default InfiniteCarousel;
